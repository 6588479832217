import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Modal from '../Modal';
import classes from './ShowTokenModal.module.css';
import Confetti from 'react-dom-confetti';
import loadingNoun from '../../assets/loading-skull-noun.gif';

//import { ImageData, getNounData } from '@nouns/assets';
//import { buildSVG } from '@nouns/sdk';
import { contract as labsTokenContract } from '../../wrappers/labsToken';
//import NounTraitsOverlay from '../NounTraitsOverlay';
import AuctionNavigation from '../AuctionNavigation';
import { setShowTokenId } from '../../state/slices/application';

import ShortAddress from '../ShortAddress';
//import { getOwnersForNft } from '../../utils/alchemy';
//import { default as config } from '../../config';
//const address: string = config.yoloTokenAddress;

//const { palette } = ImageData;

const ShowTokenModal: React.FC<{}> = props => {

  const confettiConfig = {
      angle: 80,
      spread: 180,
      startVelocity: 70,
      elementCount: 150,
      dragFriction: 0.12,
      duration: 9000,
      stagger: 8,
      width: "10px",
      height: "10px",
      colors: ["#a864fd", "#29cdff", "#8efc62", "#fa5768", "#fdff6a", '#f9b9f2']
  };

  const dispatch = useAppDispatch();
  //const emptyParts: any = null;

  // local state variables
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [imgSrc, setImgSrc] = useState(loadingNoun);
  //const [imgParts, setImgParts] = useState(emptyParts);
  const [nounOwner, setNounOwner] = useState('0x0000000000000000000000000000000000000000');
  const [showConfetti, setConfetti] = useState(false);
  const [shareCopy, setShareCopy] = useState('');
  const [mediaURL, setMediaURL] = useState('https://puppetsamurai.com/');
  const [navTokenId, setNavTokenId] = useState(0);
  
  //const prevSettledBlockHash = useAppSelector(state => state.settlement.prevSettledBlockHash);
  //const attemptedSettleBlockHash = useAppSelector(state => state.settlement.attemptedSettleBlockHash);
  //const nextNounId = useAppSelector(state => state.noun.nextNounId);

  const showTokenId = useAppSelector(state => state.application.showTokenId);
  const latestNounId = useAppSelector(state => state.labsToken.totalSupply);
    
  const showModalHandler = () => {
    setShowConnectModal(true);
  };

  const hideModalHandler = () => {
  	dispatch(setShowTokenId(0));
    setShowConnectModal(false);
  };

  const zeroPad = (num : number, places : number) => String(num).padStart(places, '0');

  // get the image of the most recently minted Noun from Twitter
  useEffect(() => {
  	if (navTokenId === undefined || navTokenId === null) return;  	
  	
    if (showConnectModal && navTokenId > 0) {
      setShareCopy(encodeURIComponent("Mint your Puppet Samurai and fight the Kaiju! #PuppetSamurai #FightTheKaiju @manofmissle"));
           
      setConfetti(false);
    }
  }, [showConnectModal, /*successfulSettle,*/ showConfetti, navTokenId, mediaURL]);

  useEffect(() => {
	const getNounImg = async () => {
      if (navTokenId === undefined || navTokenId === null) return;

	  /*      
      const seed = await YOLOTokenContract.seeds(navYOLONounId);
      
      setMediaURL(encodeURIComponent("https://yolonouns.wtf/share/share-noun?bg=" + seed.background + "&b=" + seed.body + "&a=" + seed.accessory + "&h=" + seed.head + "&g=" + seed.glasses));
      
      const { parts, background } = getNounData(seed);
      
      const svgBinary = buildSVG(parts, palette, background);
      setImgSrc(`data:image/svg+xml;base64,${btoa(svgBinary)}`);
      setImgParts(parts);
      */
      const owner = await labsTokenContract.ownerOf(navTokenId.toString());
      setNounOwner(owner!);
    }

  	
    if (navTokenId) {
      const inflatedTokenId = zeroPad(navTokenId, 5);
      setImgSrc("https://puppetsamurai.com/nft/img/" + inflatedTokenId + ".png");

      setNounOwner('0x0000000000000000000000000000000000000000');
      setMediaURL("https://puppetsamurai.com/api/share/" + navTokenId);
     
      getNounImg(); 
      showModalHandler();
      //dispatch(resetPrevSettledBlockHash());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navTokenId, dispatch]);

  useEffect(() => {
  	setNavTokenId(showTokenId!);
  }, [showTokenId, dispatch]);


  const title = 'Puppet Samurai ';
  const isFirstAuction = (navTokenId <= 1);
  const isLastAuction = (navTokenId >= latestNounId!);

  const onPrevAuctionClick = () => {
  	setNavTokenId(navTokenId - 1);
  };
  const onNextAuctionClick = () => {
  	setNavTokenId(navTokenId + 1);
  };
  
  /*
    <p className={classes.Footer}>
    	If you were YOLOing this Noun could've been yours!
    </p>
  */
  
  const yoloNounContent = (
    <>
    <Confetti active={showConfetti} config={confettiConfig}/>
    <AuctionNavigation
      isFirstAuction={isFirstAuction}
      isLastAuction={isLastAuction}
      onNextAuctionClick={onNextAuctionClick}
      onPrevAuctionClick={onPrevAuctionClick}
      auctionTitle={title + navTokenId}
    />
	<img src={imgSrc} className={classes.NounImg} alt={`NFT`} data-tip data-for="noun-traits-show-modal"/>
	<ShortAddress size={24} address={nounOwner} avatar={true} />
	<br />
    <p className={classes.Footer}>
    	<a className={classes.twitterBtn} href={`https://twitter.com/intent/tweet/?text=${shareCopy + ' ' + mediaURL}`} target="_blank" rel="noreferrer">Tweet This!</a>    
    </p>
    
    </>
    );

  return (
    <div className={classes.ModalWrapper}>
      {showConnectModal && <Modal content={yoloNounContent} onDismiss={hideModalHandler}/>}
    </div>
  )
};
export default ShowTokenModal;