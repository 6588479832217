//import React, { useEffect } from "react";
import MintButton from '../MintButton';
import classes from './MintBar.module.css';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openEthereumSocket } from '../../middleware/alchemyWebsocket';

import { Col, Row } from 'react-bootstrap';


const MintBar:React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const ethereumSocketConnected = useAppSelector(state => state.block.connected);
  //const blockhash = useAppSelector(state => state.block.blockHash);
  const totalSupply = useAppSelector(state => state.labsToken.totalSupply)!;

  const openSocket = () => {
    if (!ethereumSocketConnected) {
      dispatch(openEthereumSocket());
    }    
  }

  const mintOpts = (neutralOption: boolean) => (
    <>
      <MintButton />
    </>
  );

  /*
  const voteOpts = (neutralOption: boolean) => (
    <>
      <VoteButton voteType={VOTE_OPTIONS.voteDislike} />
      {neutralOption && <VoteButton voteType={VOTE_OPTIONS.voteShrug} />}
      <VoteButton voteType={VOTE_OPTIONS.voteLike} />
    </>
  );
  */

  const reconnectOpt = (
    <span className={classes.reconnect} onClick={openSocket}>Click Here to Reconnect</span>
  );

  /*
  const voteReconnectOpt = (
    <span className={classes.reconnect} onClick={openSocket}>Click to Enable YOLO</span>
  )
  */

  return(
  	<div className={classes.mintBar}>
        <Row >
          <Col lg={12}>

			    <div>
			      <h1 className={classes.voteTitle}>
			        Puppet Samurai {(totalSupply) ? (totalSupply + 1 ) : '?'}
			      </h1>
			    </div>

            
          </Col>
        </Row>

        <Row >
          <Col lg={12}>

		    <Row>
		      <Col xs={6} lg={6} className={classes.leftCol}>
		        <h2 className={classes.votePriceTitle}>
		          Price
		        </h2>
		      </Col>
		      <Col xs={6} lg={6}>
		        <h2 className={classes.votePriceText}>
		          Ξ 0.069
		        </h2>
		      </Col>
		    </Row>
            
            
            
          </Col>
        </Row>
      
      <Row>
        <Col lg={12} className={classes.explainerText}>

			<strong>HIGHLIGHTS:</strong>
			<ul>
				<li> <strong>ART</strong>: By Sam Ellis!</li>
				<li> <strong>ORIGINAL</strong>: Something</li>
				<li> <strong>SEASONS</strong>: Season 1</li>
			</ul>

        </Col>
      </Row>      

      <Row>
        <Col lg={12}>
  
		  
		    <div className={`${(false) ? classes.VoteBarOverlay : ''}
		      ${classes.VoteBar}`}
		    >
		      { (ethereumSocketConnected) ? mintOpts(false)
		        : !ethereumSocketConnected ? reconnectOpt
		        : '' }
		    </div>  	
          </Col>
        </Row>

     </div>		    
  );
}

export default MintBar;