// import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
//import Link from '../Link';
import fiveHousesLogos from '../../assets/five-houses-logos.png';

//import config from '../../config';
//import { buildEtherscanContractLink } from '../../utils/etherscan';


const Documentation = () => {

  /*
  const fomoDiscourseLink = (
    <Link text="Nouns DAO Discourse" url="https://discourse.nouns.wtf/t/fomo-nouns-force-mint-our-nouns/117" leavesPage={true} />
  );

  const proposalEightLink = (
    <Link text="Proposal #8" url="https://nouns.wtf/vote/8" leavesPage={true} />
  );

  const gitHubLink = (
    <Link text="YOLO Nouns GitHub" url="https://github.com/NounLabs/yolo-nouns" leavesPage={true} />
  );

  const settlementEtherscanLink = buildEtherscanContractLink(config.fomoSettlerAddress);
  const smartContractLink = (
    <Link text="FOMO Settlement Contract" url={settlementEtherscanLink} leavesPage={true} />
  );
  */

  return (
    <div className={classes.Documentation}>
      <Col lg={{ span: 7, offset: 0 }}>
        <div className={classes.headerWrapper} id="wtf">
          <h1>WTF Puppet Samurai?</h1>
          <p>
			Puppet Samurai is a CC0 project from the mind and creative works of Sam Ellis. 
			Years ago he wanted to develop a show and comic that revolved around samurai that fought kaiju – except the samurai in these stories would be puppets. 
			Due to time constraints and a full workload with other projects Puppet Samurai was put on the back burner and shelved until now. 
			Sam didn’t want the ideas to sit and gather dust and creative debt any longer and is now inviting everyone to participate in making the stories as he is making all of the art and stories CC0. 
			Like puppets, they only have value if they are used -- Tell stories and have fun with them!
		  </p>
          
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader} id="faq">The Story So Far...</Accordion.Header>
            <Accordion.Body>
            	<p>
				Puppets, Magic, and Kaiju. The Puppet Samurai gather for the hunt,  the hunt is for the Kaiju that had been destroying the outskirts of the province and getting closer to the villages. These hunts seem to happen every dawn of Quonset. The Kaiju have no thought outside of eating.
				Each champion left their respective village in hopes to save their homeland, some were royal guards of mighty houses, others belonged to guilds and were paid for their service, and some were ronin and fought to restore their honor. However, they did not know that the chancellor to their king had been summoning something wicked… He had summoned the 3 ONI, The Warlock, The Witch, and The Warrior…             	
            	</p>
	              
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>The Five Mighty Houses...</Accordion.Header>
            <Accordion.Body>
              <p>
              I never named the houses–this is now up to you as a co-developer of the story.
              <br /><br />
              <img src={fiveHousesLogos} alt='Puppet Samurai Five Houses' className={classes.fiveHousesLogos} />
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>I Minted Now What...</Accordion.Header>
            <Accordion.Body>
              <p>
				Now The Adventure Begins, 
              </p>
				<ul>
				<li>Find others that have minted with the #PuppetSamurai</li>
				<li>Create or Join a House by creating or joining a discord (invite me to it)</li>
				<li>Create your House’s Twitter Account, and invite me to follow </li>
				<li>Share Art and Stories with me and each other, I am happy to collaborate with you.</li>
				<li>Create your own collections, derivatives, extensions, merchandise, toys, apparel, shows, comics, etc… </li>
				</ul>
              <p>
				This is now your project and story as much as it was mine.              
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>About The Artist</Accordion.Header>
            <Accordion.Body>
              <p>
				Sam Ellis makes comics, cartoons, and games. 
				He has worked for a myriad of comic publishers, animation, and game studios in a variety of positions from LA to Atlanta. 
				Some of his past projects in legacy media have been: Archer, Adventure Time, Bravest Warriors, The Awesomes, Frisky Dingo, Catbug, and more. 
				Some of his past projects in Web 3.0 have been a participant in the historic <a href="https://opensea.io/collection/ape-stage-capitalism" target="_blank" rel="noreferrer">Ape Stage Capitalism</a> event, <a href="https://opensea.io/collection/nft-dungeon" target="_blank" rel="noreferrer">The NFT Dungeon</a>, <a href="https://www.degenz.co/" target="_blank" rel="noreferrer">DEGENZ</a>, <a href="https://yolonouns.wtf/" target="_blank" rel="noreferrer">YOLO Nouns</a>, 
				is an Active member of <a href="https://nouncil.wtf/" target="_blank" rel="noreferrer">Nouncil</a>, 
				and a Foundooor on <a href="https://twitter.com/noun_o_clock" target="_blank" rel="noreferrer">The Noun Square @ Noun O’ Clock</a>.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>About The Art</Accordion.Header>
            <Accordion.Body>
              <p>
				The art of Puppet Samurai⌐◨-◨ is a combination of the creative works of Sam Ellis and a combination of different digital art tools and ai programs.
			  </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>WTF ⌐◨-◨ ?</Accordion.Header>
            <Accordion.Body>
              <p>
				The ⌐◨-◨ mark seen at the end of the Puppet Samurai wordmark is known as “noggles”. 
				They have been called the “Nike Swoosh of CC0”. 
				Noggles derived from a project called <a href="https://nouns.wtf/" target="_blank" rel="noreferrer">Nouns</a> (find out more about Nouns at <a href="https://nouns.wtf/" target="_blank" rel="noreferrer">nouns.wtf</a> and <a href="https://nouns.center/" target="_blank" rel="noreferrer">nouns.center</a>) 
				  They are affixed at the end of the Puppet Samurai wordmark to let people know the project is CC0. 
				  Affixed in this fashion is what <a href="https://twitter.com/0xigami" target="_blank" rel="noreferrer">@0xigami</a> has coined as a shademark. 
				  I would like everyone to know that this project has waived all copyright, see below:
			  </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7" className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>No Copyright</Accordion.Header>
            <Accordion.Body>
              <p>
				Sam Ellis who associated a work with this deed has dedicated the work to the <a href="https://creativecommons.org/publicdomain/zero/1.0/" target="_blank" rel="noreferrer">public domain</a> by waiving all of his rights to the work worldwide under copyright law, including all related and neighboring rights, to the extent allowed by law.
				<br /><br />				
				You can copy, modify, distribute and perform Puppet Samurai⌐◨-◨, even for commercial purposes, all without asking permission.
				<br /><br />				
				This work is published from the United States.
			  </p>
            </Accordion.Body>
          </Accordion.Item>
          
        </Accordion>
      </Col>
    </div>
  );
};

export default Documentation;