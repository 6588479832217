import { BigNumber } from 'ethers';
import Section from '../../layout/Section';
import classes from './HistoryCollection.module.css';
import clsx from 'clsx';
import HistoryItem from '../HistoryItem';
import { Container, Row, Col } from 'react-bootstrap';

interface HistoryCollectionProps {
  historyCount: number;
  latestTokenId: number | null | undefined;
}

const HistoryCollection: React.FC<HistoryCollectionProps> = (props: HistoryCollectionProps) => {
  const { historyCount, latestTokenId } = props;
  if (latestTokenId === null || latestTokenId === undefined) return null;
  
  const startAtZero = BigNumber.from(latestTokenId).sub(historyCount).lt(0);

  let tokenIds: Array<BigNumber | null> = new Array(historyCount);
  tokenIds = tokenIds.fill(null).map((_, i) => {
    if (BigNumber.from(i).lte(latestTokenId)) {
      const index = startAtZero
        ? BigNumber.from(0)
        : BigNumber.from(Number(latestTokenId) - historyCount + 1);
      return index.add(i);
    } else {
      return null;
    }
  });
  
  tokenIds.reverse();
  
  const tokenContent = tokenIds.map((tokenId, i) => {
    return !tokenId ? '' : <HistoryItem key={i} tokenId={parseInt(tokenId?.toString())} />;
  });

  return (
    <div className={classes.HistorySection}>
      <Container fluid="xl">
		<Row>
          <Col lg={{ span: 12 }} >

		    <Section fullWidth={true} className={clsx(classes.historyCollectionSection)}>
		      <Container fluid>
		      	Latest Minted:
		        <Row className="justify-content-md-center">
		          <div className={clsx(classes.historyCollection)}>
		            {tokenContent}
		          </div>
		        </Row>
		      </Container>
		    </Section>

          </Col>
        </Row>
      </Container>

    </div>

  );
};

export default HistoryCollection;
