import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface labsToken {
  totalSupply?: number | null;
  maxMints?: number | null;
  paused?: boolean;
}

const initialState: labsToken = {
  totalSupply: undefined,
  maxMints: undefined,
  paused: undefined,
};

export const labsTokenSlice = createSlice({
  name: 'labsToken',
  initialState,
  reducers: {
    setTotalSupply: (state, action: PayloadAction<number | undefined>) => {
      state.totalSupply = action.payload;
    },
    setMaxMints: (state, action: PayloadAction<number | undefined>) => {
      state.maxMints = action.payload;
    },
    setPaused: (state, action: PayloadAction<boolean>) => {
      state.paused = action.payload;
    }    
  },
});

export const { setTotalSupply, setMaxMints, setPaused } = labsTokenSlice.actions;

export default labsTokenSlice.reducer;