import React, { useState, useMemo } from 'react';
//import loadingNoun from '../../assets/loading-skull-noun.gif';
import { useAppDispatch, useAppSelector } from '../../hooks';
import classes from './HistoryItem.module.css';

import { setShowTokenId } from '../../state/slices/application';

//import { ImageData, getNounData } from '@nouns/assets';
//import { buildSVG } from '@nouns/sdk';
//const { palette } = ImageData;

const HistoryItem: React.FC<{
  tokenId: number | null | undefined;
}> = props => {

  let { tokenId } = props;

  const dispatch = useAppDispatch();
  //const { library } = useEthers();

  const ethereumConnected = useAppSelector(state => state.block.connected);
  
  const showNounHandler = async () => {
    if (tokenId === null || tokenId === undefined) {
    	return;
    }
    
	dispatch(setShowTokenId(tokenId));
  };
    
  const [nounImageData, setNounImageData] = useState({
    seed: null,
    src: '',
    alt: '',
    background: '',
  });

  const zeroPad = (num : number, places : number) => String(num).padStart(places, '0');

  //const nounImageDataLoader = 
  useMemo(() => {

	//set the loading noun
	const inflatedTokenId = zeroPad(tokenId!, 5);
	setNounImageData({seed: null, src: "https://puppetsamurai.com/nft/img/" + inflatedTokenId + ".png", alt: 'Loading', background: ''});

	/*
    // Return the Loading Noun
    if (!blockhash || !nextNounId || !ethereumConnected){
    	return;
    }

	const loadSeed = async () => {

		const seed = await YOLOTokenContract.seeds(yoloNounId);
		
		const { parts, background } = getNounData(seed);
		
		const svgBinary = buildSVG(parts, palette, background);
  		const src = `data:image/svg+xml;base64,${btoa(svgBinary)}`;
  		
  		setNounImageData({seed: seed, src: src, alt: `YOLO Noun ${yoloNounId}`, background: background});
  		
    };
    loadSeed();
    */
        
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenId, ethereumConnected]);

  let wrapperClass = classes.nounWrapper
  let imgWrapper = [classes.imgWrapper]
  
  return (
    <div className={wrapperClass}>

      <div className={`${imgWrapper.join(' ')}`}>
        <img
          className={classes.img}
          src={nounImageData.src}
          alt={nounImageData.alt}
          onClick={showNounHandler}
        />
        <div className={classes.nounId}>Token {tokenId}</div>
      </div>

    </div>
  );
};

export default HistoryItem;