import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AlertModal {
  show: boolean;
  title?: string;
  message?: string;
}

interface ApplicationState {
  alertModal: AlertModal;
  mintedTokenId?: number | null;
  mintedTokenQuantity?: number | null;
  showTokenId?: number | null;
}

const initialState: ApplicationState = {
  alertModal: {
    show: false
  },  
  mintedTokenId: undefined,
  mintedTokenQuantity: undefined,
  showTokenId: undefined
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setAlertModal: (state, action: PayloadAction<AlertModal>) => {
      state.alertModal = action.payload;
    },
    setMintedTokenId: (state, action: PayloadAction<number | undefined>) => {
      state.mintedTokenId = action.payload;
    },    
    setMintedTokenQuantity: (state, action: PayloadAction<number | undefined>) => {
      state.mintedTokenQuantity = action.payload;
    },    
    setShowTokenId: (state, action: PayloadAction<number>) => {
      state.showTokenId = action.payload;
    },    
  },
});

export const { setAlertModal, setMintedTokenId, setMintedTokenQuantity, setShowTokenId } = applicationSlice.actions;

export default applicationSlice.reducer;
