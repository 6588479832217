import classes from './MintButton.module.css';
//import { VOTE_OPTIONS } from '../../state/slices/vote';
import { useAppSelector } from '../../hooks';

import { useEffect, useState, useCallback } from 'react';
import { useContractFunction } from '@usedapp/core';
//import { connectContractToSigner } from '@usedapp/core/dist/cjs/src/hooks';
import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers';
import { default as config } from '../../config';
import { Spinner } from 'react-bootstrap';
import { useAppDispatch } from '../../hooks';
import { AlertModal, setAlertModal, setMintedTokenId, setMintedTokenQuantity } from '../../state/slices/application';

import LabsMinterABI from '../../libs/abi/LabsERC721DynamicMinter.json';
//const tokenAddress: string = config.labsTokenAddress;
const minterAddress: string = config.labsMinterAddress;
const labsMinterABI = new utils.Interface(LabsMinterABI.abi);

const hardPause = config.hardPause;

const reservePrice = 0.069;
//const gasLimit = 285000;

const MintButton: React.FC<{}> = props => {
  //const activeAuction = false; //useAppSelector(state => state.auction.activeAuction);
  //const blockHash = useAppSelector(state => state.block.blockHash);
  //const blockNumber = useAppSelector(state => state.block.blockNumber);

  //const { library } = useEthers();
  const dispatch = useAppDispatch();
  const setModal = useCallback((modal: AlertModal) => dispatch(setAlertModal(modal)), [dispatch]);
  const [mintQuantity, setMintQuantity] = useState(1);
  
  const totalSupply = useAppSelector(state => state.labsToken.totalSupply)!;
  const maxMints = useAppSelector(state => state.labsToken.maxMints)!;
  const contractPaused = useAppSelector(state => state.labsToken.paused);
  
  //create new Contract (work-around for type issues)
  const labsMinterContract = new Contract(
  	minterAddress,
	labsMinterABI
  );	  	
  
  const defaultMintText = <>Mint</>;
  const [mintButtonContent, setMintButtonContent] = useState({
    loading: false,
    content: defaultMintText,
  });

  const { send: sendMint, state: mintState } = useContractFunction(
    labsMinterContract,
    'mint',
  );

  const mintHandler = async () => {
    
    sendMint(mintQuantity, {
      value: utils.parseEther((reservePrice * mintQuantity).toFixed(3).toString())
      /*gasLimit: gasLimit*/
    });
  };  

  // settle auction transaction state hook
  useEffect(() => {    
    switch (mintState.status) {      
      case 'None':      
        setMintButtonContent({
          loading: false,
          content: defaultMintText,
        });
        break;
      case 'PendingSignature':
        setMintButtonContent({ loading: true, content: <></> });
        break;
      case 'Mining':
        setMintButtonContent({ loading: true, content: <></> });
        break;
      case 'Success':
		//const blockNumber: number | undefined = mintState.receipt?.blockNumber;
		const logsLength = mintState.receipt!.logs.length; //to get the latest minted item
		const mintedTokenId = parseInt(mintState.receipt!.logs[logsLength - 1].topics[3]);
		dispatch(setMintedTokenQuantity(logsLength));
		dispatch(setMintedTokenId(mintedTokenId));

        setMintButtonContent({ loading: false, content: defaultMintText });
        break;
      case 'Fail':
        setModal({
          title: 'Transaction Failed',
          message: mintState?.errorMessage || 'Please try again.',
          show: true,
        });
        setMintButtonContent({ loading: false, content: defaultMintText });
        break;
      case 'Exception':
        setModal({
          title: 'Error',
          message: mintState?.errorMessage || 'Please try again',
          show: true,
        });
        setMintButtonContent({ loading: false, content: defaultMintText });        
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintState]);

  const handleDecrement = () => {
	if (mintQuantity <= 1) return;
	setMintQuantity(mintQuantity - 1);	
  }

  const handleIncrement = () => {
	if (mintQuantity >= 3) return;
	setMintQuantity(mintQuantity + 1);	
  }


  //disable flag // should check here for paused vs unpaused...
  //const disabled = false;//voteNotSelected || (!votingActive || activeAuction) || blockHash !== votingBlockHash
  //should also check for connected here....
  const soldOut = totalSupply >= maxMints || false;  
  const disabled = hardPause || contractPaused || soldOut || (mintState.status === 'PendingSignature') || (mintState.status === 'Mining');

  if (hardPause || contractPaused) {
  	mintButtonContent.content = <>Minting Soon</>;
  }
  if (soldOut) {
  	mintButtonContent.content = <>Sold Out</>;
  }

  return (
  	<div>
	  <div className={classes.quantityBar}>
		<button onClick={handleDecrement} className={classes.quantityButtons}>-</button>
		<input type="number" value={mintQuantity} className={classes.quantityField} readOnly />
		<button onClick={handleIncrement} className={classes.quantityButtons}>+</button>
	  </div>  	
    
      <button className={classes.bidBtn} onClick={mintHandler}
      disabled={disabled}>
        <span className={classes.voteText}> 
        	{mintButtonContent.loading ? <Spinner animation="border" /> : mintButtonContent.content}
        </span>
      </button>
    </div>
  );
};
  export default MintButton;