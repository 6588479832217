import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Modal from '../Modal';
import classes from './MintedTokenModal.module.css';
import Confetti from 'react-dom-confetti';
import loadingNoun from '../../assets/loading-skull-noun.gif';
//import { provider } from '../../config';

//import { ImageData, getNounSeedFromBlockHash, getNounData } from '@nouns/assets';
//import { buildSVG } from '@nouns/sdk';
//import { resetPrevSettledBlockHash } from '../../state/slices/settlement';
//const { palette } = ImageData;

const MintedTokenModal: React.FC<{}> = props => {

  const confettiConfig = {
      angle: 80,
      spread: 180,
      startVelocity: 70,
      elementCount: 150,
      dragFriction: 0.12,
      duration: 9000,
      stagger: 8,
      width: "10px",
      height: "10px",
      colors: ["#a864fd", "#29cdff", "#8efc62", "#fa5768", "#fdff6a", '#f9b9f2']
  };

  const dispatch = useAppDispatch();

  // local state variables
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [imgSrc, setImgSrc] = useState(loadingNoun);
  const [showConfetti, setConfetti] = useState(false);
  const [shareCopy, setShareCopy] = useState("");
  const [mediaURL, setMediaURL] = useState("https://puppetsamurai.com/");
  
  //const prevSettledBlockHash = useAppSelector(state => state.settlement.prevSettledBlockHash);
  //const attemptedSettleBlockHash = useAppSelector(state => state.settlement.attemptedSettleBlockHash);
  //const nextNounId = useAppSelector(state => state.noun.nextNounId);

  const mintedTokenId = useAppSelector(state => state.application.mintedTokenId);
  const mintedTokenQuantity = useAppSelector(state => state.application.mintedTokenQuantity);
  
  const showModalHandler = () => {
    setShowConnectModal(true);
  };

  const hideModalHandler = () => {
    setShowConnectModal(false);
  };
  
  const zeroPad = (num : number, places : number) => String(num).padStart(places, '0');

  // get the image of the most recently minted Noun from Twitter
  useEffect(() => {
  	if (mintedTokenId === undefined || mintedTokenId === null) return;  	
  	
    if (showConnectModal && mintedTokenId > 0) {
      setShareCopy(encodeURIComponent("Mint your Puppet Samurai and fight the Kaiju! #PuppetSamurai #FightTheKaiju @manofmissle"));
           
      setConfetti(true);
    }
  }, [showConnectModal, /*successfulSettle,*/ showConfetti, mintedTokenId, mediaURL]);

  useEffect(() => {

    if (mintedTokenId) {
      const inflatedTokenId = zeroPad(mintedTokenId, 5);
      setImgSrc("https://puppetsamurai.com/nft/img/" + inflatedTokenId + ".png");

      //setMediaURL(encodeURIComponent("https://yolonouns.wtf/share/share-noun?bg=" + seed.background + "&b=" + seed.body + "&a=" + seed.accessory + "&h=" + seed.head + "&g=" + seed.glasses));
      setMediaURL("https://puppetsamurai.com/api/share/" + mintedTokenId);      

      showModalHandler();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintedTokenId, dispatch]);

  const title = (mintedTokenQuantity === 1) ? 'Congrats! You minted a Puppet Samurai!' : 'Congrats! You minted ' + mintedTokenQuantity + ' Puppet Samurai!';
  const yoloNounContent = (
    <>
    <Confetti active={showConfetti} config={confettiConfig}/>
    <h3 style={{ display: 'block'}}>{title}</h3>
    <img src={imgSrc} className={classes.NounImg} alt={`Minted NFT`}/>
    <p className={classes.Footer}>
    	Will you be ready for battle?
    </p>
    <p>
    	<a className={classes.twitterBtn} href={`https://twitter.com/intent/tweet/?text=${shareCopy + ' ' + mediaURL}`} target="_blank" rel="noreferrer">Tweet This!</a>    
    </p>
    
    </>
    );

  return (
    <div className={classes.ModalWrapper}>
      {showConnectModal && <Modal content={yoloNounContent} onDismiss={hideModalHandler}/>}
    </div>
  )
};
export default MintedTokenModal;